<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-tabs>
          <b-tab title="Detail">
            <b-card>
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-img
                    :src="data.thumbnail"
                    rounded
                    alt="Rounded image"
                    class="d-inline-block mr-1 mb-1 w-100"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="8"
                >
                  <h2>{{ data.name }}</h2>
                  <small>
                    <feather-icon icon="CalendarIcon" />
                    <span class="ml-1">{{ data.start_date }} s/d {{ data.end_date }}</span>
                  </small>

                  <div class="status mt-1">
                    <b-badge
                      v-if="data.status == 'Upcoming'"
                      variant="info"
                    >
                      Upcoming
                    </b-badge>
                    <b-badge
                      v-else-if="data.status == 'On Going'"
                      variant="success"
                    >
                      On Going
                    </b-badge>
                    <b-badge
                      v-else-if="data.status == 'Done'"
                      variant="danger"
                    >
                      Done
                    </b-badge>
                  </div>

                  <div class="body">
                    <p
                      class="mt-2 text-justify"
                      v-html="data.description"
                    />
                    <p>
                      <feather-icon
                        icon="MapPinIcon"
                        class="mr-1"
                      />
                      Location : <span>{{ data.location }}</span>
                    </p>
                    <p>
                      <feather-icon
                        icon="UsersIcon"
                        class="mr-1"
                      />
                      Created By : <span>{{ data.created_by }}</span>
                    </p>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-tab>
          <b-tab title="Log">
            <b-card no-body>
              <b-card-body>
                <div class="d-flex justify-content-between flex-wrap">
                  <!-- sorting  -->
                  <b-form-group
                    label="Sort"
                    label-size="sm"
                    label-align-sm="left"
                    label-cols-sm="3"
                    label-for="sortBySelect"
                    class="mr-1 mb-md-0"
                  >
                    <b-input-group size="sm">
                      <b-form-select
                        id="sortBySelect"
                        v-model="sortBy"
                        :options="sortOptions"
                      >
                        <template #first>
                          <option value="">
                            none
                          </option>
                        </template>
                      </b-form-select>
                      <b-form-select
                        v-model="sortDesc"
                        size="sm"
                        :disabled="!sortBy"
                      >
                        <option :value="false">
                          ASC
                        </option>
                        <option :value="true">
                          DESC
                        </option>
                      </b-form-select>
                    </b-input-group>
                  </b-form-group>

                  <!-- filter -->
                  <b-form-group
                    label="Filter"
                    label-cols-sm="2"
                    label-align-sm="left"
                    label-size="sm"
                    label-for="filterInput"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                      <b-form-input
                        id="filterInput"
                        v-model="filter"
                        type="search"
                        placeholder="Type to Search"
                      />
                      <b-input-group-append>
                        <b-button
                          :disabled="!filter"
                          @click="filter = ''"
                        >
                          Clear
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </div>
              </b-card-body>

              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :per-page="perPage"
                :current-page="currentPage"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(name)="dataName">
                  <div class="d-flex flex-row">
                    <b-avatar
                      v-if="dataName.item.image !== null"
                      class="mr-50 d-flex"
                      :src="`${dataName.item.image}`"
                    />
                    <b-avatar
                      v-else
                      class="mr-50 d-flex"
                      variant="primary"
                      :text="dataName.item.name | initial"
                    />
                    <div class="d-flex flex-column">
                      <p class="m-0">
                        {{ dataName.item.name }}
                      </p>
                      <small class="text-muted">
                        {{ dataName.item.email }}
                      </small>
                    </div>
                  </div>
                </template>
                <template #cell(check_in_rawdate)="dataDate">
                  <div class="d-flex flex-row">
                    <div class="d-flex flex-column">
                      <p class="m-0">
                        {{ dataDate.item.check_in }}
                      </p>
                    </div>
                  </div>
                </template>
              </b-table>

              <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                <!-- page length -->
                <b-form-group
                  label="Per Page"
                  label-cols="6"
                  label-align="left"
                  label-size="sm"
                  label-for="sortBySelect"
                  class="text-nowrap mb-md-0 mr-1"
                >
                  <b-form-select
                    id="perPageSelect"
                    v-model="perPage"
                    size="sm"
                    inline
                    :options="pageOptions"
                  />
                </b-form-group>

                <!-- pagination -->
                <div>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </b-card-body>
            </b-card>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
  BCard, BCardBody,
  BTab, BTabs,
  BButton, BFormGroup, BInputGroup,
  BFormSelect, BFormInput, BInputGroupAppend,
  BPagination, BTable, BAvatar, BBadge, BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTab,
    BTabs,
    BButton,
    BFormGroup,
    BInputGroup,
    BFormSelect,
    BFormInput,
    BInputGroupAppend,
    BPagination,
    BTable,
    BAvatar,
    BBadge,
    BImg,
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [10, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'username', label: 'Username', sortable: true },
        { key: 'member_type', label: 'Member Type', sortable: true },
        { key: 'check_in_rawdate', label: 'Check In', sortable: true },
      ],
      active_role: null,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    items() {
      return this.$store.state.event.participants
    },
    data() {
      return this.$store.state.event.detail
    },
  },
  created() {
    this.active_role = localStorage.getItem('role')
    this.loadDataEvent()
    this.checkAuth()
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    loadDataEvent() {
      const { id } = this.$route.params
      this.$store.dispatch('event/loadDetailEvent', id)
    },
  },
}
</script>
